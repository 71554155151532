import React from "react"
import { RouteComponentProps } from "@reach/router"
import { connector, ContainerProps } from "./containers/PlayAside.containers"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { getSigninUrl } from "../../i18n/utils/getUrl"
import { AvatarAndStatsOnIndexPage } from "../AvatarAndStatsOnIndexPage/AvatarAndStatsOnIndexPage"
import { Obfuscated } from "../Obfuscated/Obfuscated"
import { LeaderboardUserItem } from "../LeaderboardUserItem/LeaderboardUserItem"
import { PointsEntity } from "../../entities/PointsEntity"
import { LeaderboardOnPlayPage } from "../LeaderboardOnPlayPage/LeaderboardOnPlayPage"
import googleLogo from "../../assets/logo/google.svg"
import { ChestExperienceRewardOnPlayPage } from "../ChestExperienceRewardOnPlayPage/ChestExperienceRewardOnPlayPage"
import { Ads, AdsTypes } from "../Ads/Ads"
import { useIntl } from "react-intl"
import { MarketplaceSubscribeOnPlayPageCTA } from "../MarketplaceSubscribeOnPlayPageCTA/MarketplaceSubscribeOnPlayPageCTA"

type Props = {
  authenticated: boolean
  fetching: boolean
  users: Array<PointsEntity & { duration: number }>
  userDaily: PointsEntity & { duration: number }
  onLoginWithGoogle: () => void
}

export const Wrapper: React.FC<Props> = (props) => {
  const intl = useIntl()
  return (
    <aside
      className={`w-full mt-2 lg:mt-0 max-w-3xl mx-auto lg:px-4 relative transition-opacity duration-300 ease-in-out`}
    >
      <div className={`w-full p-4 bg-white border-slate-100 border rounded-md`}>
        <AvatarAndStatsOnIndexPage />
      </div>

      {!props.authenticated && (
        <div className={`w-full mt-2 flex`}>
          <Obfuscated
            enabled
            to={getSigninUrl(intl.locale)}
            className="bg-white border mr-2 px-4 w-full border-slate-100 font-display text-slate-900 font-medium cursor-pointer hover:bg-blue-50 hover:text-blue-500 transition-all duration-300 ease-in-out rounded py-4 text-center"
          >
            <FormattedMessage id="drawer.signin" />
          </Obfuscated>
          <div
            onClick={props.onLoginWithGoogle}
            className="border justify-center font-display text-slate-900 font-medium border-slate-100 rounded flex items-center px-4 cursor-pointer hover:bg-blue-50 hover:text-blue-500 transition-all duration-300 ease-in-out"
          >
            <img src={googleLogo} alt="google logo" className="h-6 w-6" />
          </div>
        </div>
      )}

      <div className="mt-2">
        <Ads type={AdsTypes.SQUARE} media="all" headline />
      </div>

      <MarketplaceSubscribeOnPlayPageCTA />

      <ChestExperienceRewardOnPlayPage />

      <LeaderboardOnPlayPage />

      <div className="border border-slate-100 mt-2 rounded-md">
        <div className="px-4 mt-4">
          <p className="font-semibold text-2xl  font-display text-slate-900">
            <FormattedMessage id="daily.items.best.title" />
          </p>
          <p className="text-slate-700 text-sm">
            <FormattedMessage id="daily.items.best.description" />
          </p>
        </div>
        <div>
          {props.users.map((user, index) => (
            <LeaderboardUserItem
              small
              fluid
              chest={
                index === 0 ? "ultimate" : index === 1 ? "emerald" : "gold"
              }
              avatar={user.avatar}
              isTheUser={user.userId === props.userDaily?.userId}
              duration={user.duration}
              position={index + 1}
              username={user.username}
              key={index}
            />
          ))}
        </div>
      </div>
    </aside>
  )
}

export const Container: React.FC<ContainerProps & RouteComponentProps> = (
  props
) => <Wrapper {...props} />

export const PlayAside = connector(Container)
