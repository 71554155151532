import React, { useEffect } from "react"
import { RouteComponentProps } from "@reach/router"
import { MatrixEntity } from "../../entities/GameEntity"
import { Grid } from "../Grid/Grid"
import { connector, ContainerProps } from "./containers/Index.containers"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { Controls } from "../Controls/Controls"
import { ControlsMobile } from "../ControlsMobile/ControlsMobile"
import { BackgroundShapes } from "../BackgroundShapes"
import { Timer } from "../Timer/Timer"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"
import { Confetti } from "../Confetti/Confetti"
import { Success } from "../Success/Success"
import { Navbar } from "../Navbar/Navbar"
import { AchievementsModal } from "../AchievementsModal/AchievementsModal"
import urls from "../../i18n/urls/levels.json"
import levels from "../../constants/levels.json"
import { getLevelUrl, getPrintUrl, getSigninUrl } from "../../i18n/utils/getUrl"
import { AvatarAndStatsOnIndexPage } from "../AvatarAndStatsOnIndexPage/AvatarAndStatsOnIndexPage"
import { Obfuscated } from "../Obfuscated/Obfuscated"
import { LeaderboardUserItem } from "../LeaderboardUserItem/LeaderboardUserItem"
import { PointsEntity } from "../../entities/PointsEntity"
import { useLocation } from "@reach/router"
import { Link } from "../Link/Link"
import { Article } from "../Article/Article"
import { ArticleEntity } from "../../entities/ArticleEntity"
import { Badge } from "../Badge/Badge"
import clsx from "clsx"
import { LeaderboardOnPlayPage } from "../LeaderboardOnPlayPage/LeaderboardOnPlayPage"
import googleLogo from "../../assets/logo/google.svg"
import { NewsEntity } from "../../entities/NewsEntity"
import { GridEmpty } from "../Grid/components/GridEmpty"
import { HeartIcon } from "@heroicons/react/24/solid"
import { PrinterIcon, PlusSmallIcon } from "@heroicons/react/20/solid"
import { ChestExperienceRewardOnPlayPage } from "../ChestExperienceRewardOnPlayPage/ChestExperienceRewardOnPlayPage"
import { Ads, AdsTypes } from "../Ads/Ads"
import { MarketplaceSubscribeCTA } from "../MarketplaceSubscribeCTA/MarketplaceSubscribeCTA"
import { MarketplaceSubscribeOnPlayPageCTA } from "../MarketplaceSubscribeOnPlayPageCTA/MarketplaceSubscribeOnPlayPageCTA"
import { PlayAside } from "../PlayAside/PlayAside"

type Props = {
  langKey: string
  level: "easy" | "medium" | "hard" | "expert" | "evil"
  dark: boolean
  onClickDarkMode: Function
  matrix: MatrixEntity
  onMount: Function
  paused: boolean
  authenticated: boolean
  fetching: boolean
  article: ArticleEntity
  errors: number
  news: NewsEntity
  shouldShowAdsError: boolean
  users: Array<PointsEntity & { duration: number }>
  userDaily: PointsEntity & { duration: number }
  onLoginWithGoogle: () => void
}

const levelToColor = (level: string) => {
  if (level === "easy") return "blue"
  if (level === "medium") return "yellow"
  if (level === "hard") return "red"
  if (level === "expert") return "gray"
  if (level === "evil") return "black"
}

export const IndexWrapper: React.FC<Props> = (props) => {
  const location = useLocation()
  useEffect(() => {
    props.onMount()
  }, [])

  const intl = useIntl()

  return (
    <>
      <AchievementsModal />

      <Success lang={props.langKey} />

      <div className="relative overflow-hidden">
        <BackgroundShapes step={props.paused ? 0 : 1} absolute />

        <Navbar langKey={props.langKey} level={props.level} news={props.news} />

        <div className="px-2">
          <Article
            {...props.article}
            illustration={null}
            section="game"
            hideContent
            category={null}
            level={props.level}
          />

          <ul className="flex relative items-center flex-wrap justify-center">
            {levels.map((level) => (
              <li key={level} className="mb-2 mr-2">
                <Link url={getLevelUrl(intl.locale, level)}>
                  <Badge
                    disabled={level !== props.level}
                    component="span"
                    color={levelToColor(level)}
                  >
                    {urls[intl.locale][level]}
                  </Badge>
                </Link>
              </li>
            ))}
          </ul>

          <div className="container relative flex max-w-6xl m-auto">
            <div className="flex items-center lg:max-w-3xl mx-auto lg:mx-0 container justify-between sm:pb-2 sm:pt-4">
              <div className="flex items-center font-medium">
                <div className="flex items-center">
                  <div className="relative">
                    <HeartIcon
                      className={clsx(
                        "h-6 w-6",
                        props.errors >= 3 && "text-slate-300",
                        props.errors < 3 && "text-red-400"
                      )}
                    />
                    {props.errors > 3 && (
                      <div className="absolute flex items-center text-slate-700 justify-center inset-0 font-display font-semibold text-xs">
                        {props.errors - 3}
                      </div>
                    )}
                  </div>

                  <HeartIcon
                    className={clsx(
                      "h-6 w-6",
                      props.errors >= 2 && "text-slate-300",
                      props.errors < 2 && "text-red-400"
                    )}
                  />
                  <HeartIcon
                    className={clsx(
                      "h-6 w-6",
                      props.errors >= 1 && "text-slate-300",
                      props.errors < 1 && "text-red-400"
                    )}
                  />

                  {props.errors <= -1 && (
                    <HeartIcon className={clsx("h-6 w-6 text-emerald-400")} />
                  )}
                  {props.errors <= -2 && (
                    <HeartIcon className={clsx("h-6 w-6 text-emerald-400")} />
                  )}
                  {props.errors <= -3 && (
                    <HeartIcon className={clsx("h-6 w-6 text-emerald-400")} />
                  )}

                  {props.errors > 0 && (
                    <div
                      onClick={() => {
                        navigate(location.pathname + "#errors-ads=open")
                      }}
                      className="ml-1 h-6 w-6 rounded-md bg-slate-200 text-slate-500 hover:bg-slate-300 hover:text-slate-600 cursor-pointer"
                    >
                      <PlusSmallIcon className="h-6 w-6" />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center">
                <Timer />
                <Link
                  url={getPrintUrl(props.langKey, props.level)}
                  title={intl.formatMessage({
                    id: "print/link/" + props.level,
                  })}
                  className="px-2 hidden md:block -mr-1 py-2 text-slate-400 transition duration-300 ease-in-out rounded cursor-pointer hover:bg-blue-50 hover:text-blue-500"
                >
                  <PrinterIcon className="w-5 h-5" />
                </Link>
              </div>
            </div>
          </div>

          <div className="container lg:flex max-w-6xl mx-auto">
            <div className="flex-shrink-0 w-full lg:max-w-3xl relative flex justify-center mx-auto">
              <div className="w-full">
                <div className="flex justify-center">
                  <div className="w-full">
                    {props.matrix ? (
                      <Grid matrix={props.matrix} />
                    ) : (
                      <GridEmpty />
                    )}
                  </div>
                  <div className="flex-shrink-0 hidden pl-4 sm:block">
                    <Controls level={props.level} />
                  </div>
                </div>

                <div className="">
                  <div className="mt-1 sm:hidden">
                    <ControlsMobile level={props.level} />
                  </div>

                  <div className="w-full mt-2 sm:hidden">
                    <div
                      onClick={() =>
                        navigate(
                          window?.location.pathname + "#new-game-modal=open"
                        )
                      }
                      className="w-full py-5 font-medium text-center uppercase font-display text-white bg-blue-500 rounded cursor-pointer hover:bg-blue-600"
                    >
                      <FormattedMessage id="play.controls.new-game" />
                    </div>
                  </div>

                  <div className="mt-4">
                    <Ads type={AdsTypes.HORIZONTAL} media="desktop" />
                  </div>

                  <div className="lg:hidden">
                    <PlayAside />
                  </div>

                  <main>
                    <Article
                      {...props.article}
                      illustration={null}
                      articles={[]}
                      section="game"
                      hideTitle
                      category={null}
                    />
                  </main>
                </div>
              </div>
            </div>

            <div className="hidden overflow-hidden w-full lg:block">
              <PlayAside />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const IndexContainer: React.FC<ContainerProps & RouteComponentProps> = (
  props
) => <IndexWrapper {...props} />

export default connector(IndexContainer)
