import React from "react"

type Props = {}

export const GridEmpty: React.FC<Props> = (props) => {
  return (
    <div className="flex justify-center font-display w-full sm:items-center">
      <div className="relative w-full">
        <div className={`grid grid-cols-9 bg-white`}>
          {Array.from({ length: 9 * 9 }).map((v, index) => (
            <div
              key={index}
              className={`border-t border-r border-slate-200  `}
              style={{ paddingTop: "100%" }}
            ></div>
          ))}
        </div>

        <div
          className={`absolute inset-0 grid h-full w-full grid-cols-3 pointer-events-none`}
        >
          <div
            className="border border-t-2 border-l-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-t-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-t-2 border-r-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-l-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-r-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-b-2 border-l-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-b-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-b-2 border-r-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
        </div>
      </div>
    </div>
  )
}
