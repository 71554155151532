import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props: any) => ({
  isOpen: props.isOpen,
  dark: state.darkMode.dark,
})

const mapDispatch = (dispatch: any) => ({
  onNew: () => {
    dispatch(actions.play.$selectGame())
  },
  onClose: () => {
    window?.history.back()
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
