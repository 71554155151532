import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props: { isSuccessPage?: boolean }) => ({
  experience: state.points.leaderboardPoints,
  authenticated: state.auth.authenticated,
  ...props,
})

const mapDispatch = (dispatch: any) => ({})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
