export const createBadgeUrl = (params: {
  level: string
  duration: number
  errors: number
  clues: number
  lang: string
}) => {
  return (
    "https://share.sudoku.academy/" +
    window.btoa(
      unescape(
        encodeURIComponent(
          `level=${params.level}&time=${params.duration}&errors=${params.errors}&clues=${params.clues}&lang=${params.lang}`
        )
      )
    )
  )
}
