import React from "react"
import { UserEntity } from "../../../../entities/UserEntity"
import { LeaderboardUserItem } from "../../../LeaderboardUserItem/LeaderboardUserItem"
import { LeaderboardEntity } from "../../../../entities/LeaderboardEntity"

const getChestByPosition = (position: number) => {
  if (position === 1) return "emerald"
  if (position === 2) return "gold"
  if (position === 3) return "silver"
  return false
}

export const LeagueUsersRanking: React.FC<{
  users: LeaderboardEntity[]
  userId: UserEntity["id"] | null
  small?: boolean
  fluid?: boolean
}> = (props) => {
  return (
    <>
      {props.users.map(({ username, userId, points, avatar }, index) => {
        const isTheUser = userId === props.userId

        return (
          <React.Fragment key={userId}>
            <LeaderboardUserItem
              isTheUser={isTheUser}
              avatar={avatar}
              username={username || `#${userId.toLowerCase().slice(0, 10)}`}
              points={points}
              position={index + 1}
              key={index}
              chest={getChestByPosition(index + 1)}
              small={props.small}
              fluid={props.fluid}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}
