import { connect, ConnectedProps } from "react-redux"
import { GameEntity, MatrixEntity } from "../../../entities/GameEntity"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props) => ({
  matrix: state.play.matrix as MatrixEntity,
  dark: state.darkMode.dark,
  langKey: props.pageContext.langKey,
  level: props.pageContext.level,
  paused: state.play.paused,
  authenticated: state.auth.authenticated,
  users: state.daily.users,
  userDaily: state.daily.user,
  fetching: state.daily.fetching,
  article: props.article,
  errors: state.play.errors,
  news: props.news,
  shouldShowAdsError: state.play.errors > 0,
})

const mapDispatch = (
  dispatch: any,
  props: { games: GameEntity[]; pageContext: { level: string } }
) => ({
  onMount: async () => {
    await dispatch(actions.play.storeGames({ games: props.games }))

    await dispatch(
      actions.play.checkLocalStorageAndSelectGame({
        level: props.pageContext.level,
      })
    )

    await dispatch(
      actions.daily.$FetchOnMount({ level: props.pageContext.level })
    )
  },

  onClickDarkMode() {
    dispatch(actions.darkMode.$toggleDarkMode())
  },

  onLoginWithGoogle() {
    dispatch(actions.auth.$authenticateWithGoogle())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
