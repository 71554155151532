import clsx from "clsx"
import React from "react"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import {
  connector,
  ContainerProps,
} from "./containers/ChestExperienceRewardOnPlayPage.container"

const getChest = (experience: number) => {
  if (experience >= 1000) return "ultimate"
  if (experience >= 500) return "emerald"
  if (experience >= 100) return "gold"
  return "silver"
}

export const Wrapper: React.FC<{
  experience: number
  authenticated: boolean
  isSuccessPage?: boolean
}> = (props) => {
  return (
    <div
      className={clsx(
        "relative border overflow-hidden bg-white border-slate-100 rounded-md mt-2 pb-4",
        props.isSuccessPage && "border-transparent"
      )}
    >
      <div className="w-full ">
        <div className="pt-4">
          <div className="flex items-center justify-between px-4">
            <div className="font-display text-2xl leading-tight text-slate-900 font-semibold tracking-tighter">
              <FormattedMessage id="chest/block/title" />
            </div>
          </div>

          {!props.authenticated && (
            <p className="text-xs mb-1 font-display font-semibold px-4 text-blue-500">
              <FormattedMessage id={`feature/auth/warning`} />
            </p>
          )}

          <p className="text-sm px-4 text-slate-700">
            <FormattedMessage id="chest/block/description" />
          </p>

          <div className="relative w-full pl-4 mt-4 flex">
            <img
              src={`/chest/${getChest(props.experience)}/closed.svg`}
              className="w-16 flex-shrink-0"
              alt="chest icon"
            />

            <div className="px-4 pr-6 w-full">
              <div className="font-display leading-none flex items-center">
                <FormattedMessage
                  id={`chest/block/${getChest(props.experience)}/title`}
                />
              </div>
              <div className="text-xs">
                <FormattedMessage
                  id={`chest/block/${getChest(props.experience)}/description`}
                />
              </div>

              <div className="h-2 mt-2 w-full rounded-full relative bg-slate-200">
                <div className="h-full grid grid-cols-4">
                  <div
                    className="h-full max-w-full bg-blue-500 rounded-full"
                    style={{
                      width: (props.experience / 100) * 100 + "%",
                    }}
                  ></div>
                  <div
                    className="h-full max-w-full bg-blue-500 rounded-full"
                    style={{
                      width:
                        ((props.experience >= 100
                          ? props.experience - 100
                          : 0) /
                          400) *
                          100 +
                        "%",
                    }}
                  ></div>
                  <div
                    className="h-full max-w-full bg-blue-500 rounded-full"
                    style={{
                      width:
                        ((props.experience >= 500
                          ? props.experience - 500
                          : 0) /
                          500) *
                          100 +
                        "%",
                    }}
                  ></div>
                  <div
                    className="h-full max-w-full bg-blue-500 rounded-full"
                    style={{
                      width:
                        ((props.experience >= 1000
                          ? props.experience - 1000
                          : 0) /
                          3000) *
                          100 +
                        "%",
                    }}
                  ></div>
                </div>

                <div className="absolute left-0 inset-y-0 transform m-auto">
                  <div
                    className={clsx(
                      "h-4 w-4 rounded-full  absolute left-0 inset-y-0 transform m-auto",
                      props.experience >= 100 ? "bg-blue-500" : "bg-slate-200"
                    )}
                  ></div>
                  <div className="text-center mt-3 text-xs font-display text-blue-500 font-semibold">
                    {props.experience}
                  </div>
                </div>

                <div className="w-16 absolute left-1/4 inset-y-0 transform m-auto -translate-x-1/2">
                  <div
                    className={clsx(
                      "h-4 w-4 rounded-full  absolute left-0 right-0 inset-y-0 transform m-auto",
                      props.experience >= 100 ? "bg-blue-500" : "bg-slate-200"
                    )}
                  ></div>
                  <div className="text-center mt-3 text-xs font-display text-slate-500 font-semibold">
                    100
                  </div>
                </div>

                <div className="w-16 absolute left-2/4 inset-y-0 transform m-auto -translate-x-1/2">
                  <div
                    className={clsx(
                      "h-4 w-4 rounded-full  absolute left-0 right-0 inset-y-0 transform m-auto",
                      props.experience >= 500 ? "bg-blue-500" : "bg-slate-200"
                    )}
                  ></div>
                  <div className="text-center mt-3 text-xs font-display text-slate-500 font-semibold">
                    500
                  </div>
                </div>

                <div className="w-16 absolute left-3/4 inset-y-0 transform m-auto -translate-x-1/2">
                  <div
                    className={clsx(
                      "h-4 w-4 rounded-full  absolute left-0 right-0 inset-y-0 transform m-auto",
                      props.experience >= 1000 ? "bg-blue-500" : "bg-slate-200"
                    )}
                  ></div>
                  <div className="text-center mt-3 text-xs font-display text-slate-500 font-semibold">
                    1000
                  </div>
                </div>

                <div className="w-16 absolute left-full inset-y-0 transform m-auto -translate-x-1/2">
                  <div
                    className={clsx(
                      "h-4 w-4 rounded-full  absolute left-0 right-0 inset-y-0 transform m-auto",
                      props.experience >= 4000 ? "bg-blue-500" : "bg-slate-200"
                    )}
                  ></div>
                  <div className="text-center mt-3 text-xs font-display text-slate-500 font-semibold">
                    4000
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const ChestExperienceRewardOnPlayPage = connector(Container)
