import React, { ReactNode, useState } from "react"
import LinkIcon from "@heroicons/react/24/solid/LinkIcon"
import CheckIcon from "@heroicons/react/24/solid/CheckIcon"
import ShareIcon from "@heroicons/react/24/solid/ShareIcon"
import { useIntl } from "react-intl"
import { StatForShareableLink } from "../../../entities/StatEntity"
import { createBadgeUrl } from "../../../utils/createBadgeUrl"

import Facebook from "../../../assets/badges/facebook.svg"
import Twitter from "../../../assets/badges/twitter.svg"
import Weibo from "../../../assets/badges/weibo.svg"
import Vk from "../../../assets/badges/vk.svg"
import WhatsApp from "../../../assets/badges/whatsapp.svg"

const Network: React.FC<{
  backgroundColor: string
  share?: string
  onClick?: Function
  children: ReactNode
}> = ({ children, backgroundColor, share, onClick }) => {
  if (share)
    return (
      <a
        href={share}
        target="_blank"
        className="flex items-center justify-center w-12 h-12 transition-shadow duration-300 rounded-lg shadow cursor-pointer hover:shadow-lg"
        style={{ backgroundColor }}
      >
        {children}
      </a>
    )
  return (
    <div
      {...(onClick ? { onClick: () => onClick() } : {})}
      className="flex items-center justify-center w-12 h-12 transition-shadow duration-300 rounded-lg shadow cursor-pointer hover:shadow-lg"
      style={{ backgroundColor }}
    >
      {children}
    </div>
  )
}

const CopyToClipboad: React.FC<{
  text: string
  children: (copied: boolean) => React.ReactNode
}> = (props) => {
  const [copied, setCopied] = useState(false)
  return (
    <div
      onClick={() => {
        const copyText = document.querySelector<HTMLInputElement>("#copy")
        if (copyText && copyText.select) copyText.select()
        if (document.execCommand) document.execCommand("copy")
        if (copyText && copyText.blur) copyText.blur()
        setCopied(true)
      }}
    >
      {props.children(copied)}
      <input
        id="copy"
        className="absolute"
        readOnly
        onFocus={(e) => e.preventDefault()}
        style={{ left: "-100000px", top: "-10000px" }}
        value={props.text}
      />
    </div>
  )
}

export const Share: React.FC<{ stats: StatForShareableLink; lang: string }> = (
  props
) => {
  const intl = useIntl()
  const shareable = !!navigator.share

  const shareLink = `${createBadgeUrl({
    ...props.stats,
    lang: props.lang,
  })}`

  return (
    <div className="flex items-end justify-between mx-auto mt-4 w-80">
      <Network
        backgroundColor="#1877F2"
        share={`https://www.facebook.com/sharer.php?u=${shareLink}`}
      >
        <img src={Facebook} className="w-8 h-8" />
      </Network>
      <Network
        backgroundColor="#1DA1F2"
        share={`https://twitter.com/intent/tweet?url=${shareLink}&text=${encodeURIComponent(
          intl.formatMessage({
            id: "share.success.twitter",
          })
        )}`}
      >
        <img src={Twitter} className="w-8 h-8" />
      </Network>
      <Network
        backgroundColor="#25D366"
        share={`https://api.whatsapp.com/send?text=${intl.formatMessage({
          id: "share.success.whatsapp",
        })}&url=${shareLink}`}
      >
        <img src={WhatsApp} className="w-8 h-8" />
      </Network>
      <Network
        backgroundColor="#DF2029"
        share={`http://service.weibo.com/share/share.php?url=${shareLink}&appkey=&title=${intl.formatMessage(
          {
            id: "share.success.weibo",
          }
        )}&pic=&ralateUid=`}
      >
        <img src={Weibo} className="w-8 h-8" />
      </Network>
      <Network
        backgroundColor="#4C75A3"
        share={`http://vk.com/share.php?url=${shareLink}&comment=${intl.formatMessage(
          {
            id: "share.success.vk",
          }
        )}`}
      >
        <img src={Vk} className="w-8 h-8" />
      </Network>
      {shareable && (
        <Network
          backgroundColor="#111827"
          onClick={() =>
            navigator.share({
              url: shareLink,
            })
          }
        >
          <ShareIcon className="w-6 h-6 text-white" />
        </Network>
      )}
      {!shareable && (
        <CopyToClipboad text={shareLink}>
          {(copied: boolean) => {
            if (copied)
              return (
                <Network backgroundColor="#10B981">
                  <CheckIcon className="w-8 h-8 text-white" />
                </Network>
              )

            return (
              <Network backgroundColor="#111827">
                <LinkIcon className="w-6 h-6 text-white" />
              </Network>
            )
          }}
        </CopyToClipboad>
      )}
    </div>
  )
}
