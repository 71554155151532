import React, { Fragment, useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { useLocation } from "@reach/router"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { navigate } from "gatsby"
import PlayIcon from "@heroicons/react/24/solid/PlayIcon"
import HeartIcon from "@heroicons/react/24/solid/HeartIcon"
import { Ads, AdsTypes } from "../Ads/Ads"
import clsx from "clsx"
import {
  connector,
  ContainerProps,
} from "./containers/ErrorsGetNewByAdsModal.container"
import { Text } from "../Mdx/components"
import { RECOVER_HEART_PRICE } from "../../constants/emeralds"

const AdsStep: React.FC<{
  onClick: () => void
}> = (props) => {
  const [timeoutAds, setTimeoutAds] = useState(10)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeoutAds((timeout) => {
        return timeout - 1
      })
    }, 1000)

    setTimeout(() => {
      setTimeoutAds(0)
      clearInterval(interval)
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="p-4">
      <Ads type={AdsTypes.SQUARE} show media="all" />

      <div className="mt-4">
        <div
          className={clsx(
            timeoutAds === 0 &&
              "bg-blue-500 text-white hover:bg-blue-600 cursor-pointer",
            timeoutAds != 0 && "bg-slate-200 text-gray-400 cursor-not-allowed",
            "flex justify-center font-display w-full px-4 py-4 font-medium border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          )}
          onClick={() => {
            if (timeoutAds === 0) props.onClick()
          }}
        >
          <HeartIcon className="h-6 w-6 mr-2" />
          <FormattedMessage id="recover-error-ads-modal/button" />
          {timeoutAds > 0 && <span className="ml-2">{`(${timeoutAds}s)`}</span>}
        </div>
      </div>
    </div>
  )
}

export type Props = {
  onAfterSeeAds: () => void
  spendCoinForOneHeart: () => void
  canBuyHeart: boolean
}

export const Wrapper: React.FC<Props> = (props) => {
  const location = useLocation()
  const [showingAds, setShowingAds] = useState(false)
  const isOpen = location.href?.includes("errors-ads=open") || false

  const onClose = () => {
    navigate(-1)
    setShowingAds(false)
  }

  const onShowAds = () => {
    setShowingAds(true)
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 z-10 overflow-y-auto`}
        onClose={onClose}
      >
        <div className="min-h-screen p-2 sm:block">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-slate-500 bg-opacity-75 " />
          </Transition.Child>

          <div className="fixed inset-x-0 bottom-0 md:inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center items-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="relative max-w-xl w-full mx-auto rounded-lg shadow-lg">
                  <div className="bg-white rounded-lg shadow-xs">
                    {!showingAds && (
                      <div className="pt-5 pb-6">
                        <div className="flex px-6 items-baseline justify-between">
                          <div className="font-display text-xl font-semibold text-pink-400">
                            <FormattedMessage id="recover-error-ads-modal/title" />
                          </div>
                          <div className="-mr-2">
                            <button
                              type="button"
                              onClick={onClose}
                              className="inline-flex items-center justify-center p-2 text-slate-400 bg-slate-100 transition duration-150 ease-in-out rounded-md   hover:text-slate-500 hover:bg-slate-200 focus:outline-none focus:bg-slate-100 focus:text-slate-500"
                            >
                              <svg
                                className="w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>

                        <div className="px-2">
                          <Text>
                            <FormattedMessage
                              id="recover-error-ads-modal/description"
                              values={{
                                e: RECOVER_HEART_PRICE,
                              }}
                            />
                          </Text>
                        </div>

                        {!props.canBuyHeart && (
                          <div className="px-2 font-display text-amber-500">
                            <Text>
                              <FormattedMessage id="recover-error-ads-modal/no-more-emeralds/description" />
                            </Text>
                          </div>
                        )}

                        <div className="mt-6 px-4">
                          <div className="grid grid-cols-2 gap-2">
                            {props.canBuyHeart && (
                              <div
                                className="flex justify-center font-display w-full px-4 py-4 font-medium text-white bg-blue-500 border border-transparent rounded-md cursor-pointer hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                onClick={() => {
                                  props.spendCoinForOneHeart()
                                  onClose()
                                }}
                              >
                                <FormattedMessage
                                  id="recover-error-ads-modal/emeralds/button"
                                  values={{
                                    E: RECOVER_HEART_PRICE,
                                  }}
                                />
                              </div>
                            )}
                            <div
                              className="flex justify-center font-display w-full px-4 py-4 font-medium text-white bg-blue-500 border border-transparent rounded-md cursor-pointer hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                              onClick={() => onShowAds()}
                            >
                              <PlayIcon className="h-6 w-6 mr-2" />{" "}
                              <FormattedMessage id="recover-error-ads-modal/button" />
                            </div>
                          </div>
                          <div
                            className="flex mt-2 justify-center font-display w-full px-4 py-4 font-medium text-slate-700 bg-white border border-slate-200 rounded-md cursor-pointer hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                            onClick={() => onClose()}
                          >
                            <FormattedMessage id="avatar/modal/cancel" />
                          </div>
                        </div>
                      </div>
                    )}

                    {showingAds && (
                      <AdsStep
                        onClick={() => {
                          props.onAfterSeeAds()
                          navigate(-1)
                          setTimeout(() => {
                            setShowingAds(false)
                          }, 1000)
                        }}
                      />
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const ErrorsGetNewByAdsModal = connector(Container)
