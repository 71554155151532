import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (
  state: RootState,
  props: {
    level: string
    hideNewGame?: boolean
    hideLabels?: boolean
    remainingClues?: number
  }
) => {
  return {
    isInDraftMode: state.play.draftMode,
    level: props.level,
    remainingClues: props.hasOwnProperty("remainingClues")
      ? props.remainingClues
      : 3 - (state.play.actual?.clues || 0),
    hideNewGame: props.hideNewGame,
    hideLabels: props.hideLabels,
  }
}

const mapDispatch = (dispatch: any) => ({
  onClick: (value: number) => {
    return dispatch(
      actions.play.$changeCelOrDraft({
        value: value ? Number(value) : value,
      })
    )
  },
  onDraft: () => {
    dispatch(actions.play.toggleDraftMode())
  },
  onUndo: () => {
    dispatch(actions.play.$undo())
  },
  onErase: () => {
    dispatch(actions.play.$changeCel({ value: 0 }))
  },
  onClue: () => {
    dispatch(actions.play.$showClue())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
