import React, { useState } from "react"
import { FormattedMessage } from "../../FormattedMessage/FormattedMessage"

export const HeartBonus = () => {
  return (
    <div className="bg-white max-w-xl mx-auto">
      <div className="p-4 pt-8">
        <div className="mx-auto">
          <img
            src={`/marketplace/hearts.svg`}
            width={"90px"}
            height={"90px"}
            className="mx-auto"
          />
        </div>

        <p className="font-display mt-6 font-medium text-xl text-center">
          <FormattedMessage id="success/heart-bonus/title" />
        </p>
        <p className="text-center mt-2 text-slate-700">
          <FormattedMessage id="success/heart-bonus/description" />
        </p>
      </div>
    </div>
  )
}
