import { PageProps } from "gatsby"
import { graphql } from "gatsby"
import React from "react"
import { useIntl } from "react-intl"

import { Seo } from "../components/Seo/Seo"
import { GameEntity } from "../entities/GameEntity"
import { Footer } from "../components/Footer/Footer"

import Index from "../components/pages/Index"
import { SelectLanguageModal } from "../components/SelectLanguageModal/SelectLanguageModal"
import { Drawer } from "../components/Drawer/Drawer"
import { GlobalWrapper } from "../components/GlobalWrapper/GlobalWrapper"
import { ArticleEntity } from "../entities/ArticleEntity"
import { LeaguePromotionModal } from "../components/LeaguePromotionModal/LeaguePromotionModal"
import { ErrorsGetNewByAdsModal } from "../components/ErrorsGetNewByAdsModal/ErrorsGetNewByAdsModal"
import { MobileNavbar } from "../components/MobileNavbar/MobileNavbar"

type Props = PageProps<
  {
    allGame: {
      edges: Array<{
        node: GameEntity
      }>
    }
    mdx?: {
      id: string
      body: string
      frontmatter: {
        created_at: string
        lang: string
        level: string
        seo_description: string
        seo_title: string
        title: string
        updated_at: string
        faq: Array<{
          question: string
          answer: string
        }> | null
      }
    }
  },
  {
    news: ArticleEntity | null
    article: ArticleEntity
    games?: ArticleEntity[]
    langKey: string
    level: "easy" | "medium" | "hard" | "evil" | "expert"
    isIndex: boolean
    canonicals: Array<{
      lang: string
      url: string
      isDefault: boolean
      label: string
    }>
  }
>

const IndexPage: React.FC<Props> = (props) => {
  const intl = useIntl()

  return (
    <GlobalWrapper>
      <Seo
        ads
        paddle
        title={
          props.pageContext.article?.meta?.title ||
          props.data.mdx?.frontmatter?.seo_title ||
          intl.formatMessage({
            id: `seo.${
              props.pageContext.isIndex ? "index" : props.pageContext.level
            }.title`,
          })
        }
        description={
          props.pageContext.article?.meta?.description ||
          props.data.mdx?.frontmatter?.seo_description ||
          intl.formatMessage({
            id: `seo.${
              props.pageContext.isIndex ? "index" : props.pageContext.level
            }.description`,
          })
        }
        lang={props.pageContext.langKey}
        langUrls={props.pageContext.canonicals}
        faq={props.data.mdx?.frontmatter.faq}
        theme={props.pageContext.level}
      />

      <LeaguePromotionModal />

      <Drawer langKey={props.pageContext.langKey} />
      <SelectLanguageModal languages={props.pageContext.canonicals} />
      <ErrorsGetNewByAdsModal />

      <Index
        {...props}
        article={props.pageContext.article}
        games={props.data.allGame.edges.map(({ node }) => ({
          ...node,
        }))}
        news={props.pageContext.news}
      />

      <MobileNavbar
        langKey={props.pageContext.langKey}
        {...(props.pageContext.isIndex
          ? {}
          : { level: props.pageContext.level })}
      />

      <Footer
        lang={props.pageContext.langKey}
        isIndex={props.pageContext.isIndex}
      />
    </GlobalWrapper>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery($level: String, $langKey: String, $content: String) {
    allGame(filter: { difficulty: { eq: $level } }) {
      edges {
        node {
          id
          solution
          initial
          difficulty
        }
      }
    }
    mdx(
      frontmatter: {
        lang: { eq: $langKey }
        level: { eq: $content }
        category: { eq: "game" }
      }
    ) {
      id
      body
      frontmatter {
        created_at
        lang
        level
        seo_description
        seo_title
        title
        updated_at
        faq {
          question
          answer
        }
      }
    }
  }
`
