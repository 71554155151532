import React from "react"
import { useEffect } from "react"
import PlayCircleIcon from "@heroicons/react/24/solid/PlayCircleIcon"
import { MatrixEntity } from "../../entities/GameEntity"
import { Cel } from "../Cel/Cel"
import { connector, ContainerProps } from "./containers/Grid.containers"

type Props = {
  matrix: MatrixEntity
  paused: boolean
  onResume: () => any
  onChange: (number: number) => any
  onRight: () => any
  onLeft: () => any
  onDown: () => any
  onUp: () => any
}

export const GridWrapper: React.FC<Props> = (props) => {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "1") props.onChange(1)
      else if (e.key === "2") props.onChange(2)
      else if (e.key === "3") props.onChange(3)
      else if (e.key === "4") props.onChange(4)
      else if (e.key === "5") props.onChange(5)
      else if (e.key === "6") props.onChange(6)
      else if (e.key === "7") props.onChange(7)
      else if (e.key === "8") props.onChange(8)
      else if (e.key === "9") props.onChange(9)
      else if (e.code === "Digit1") props.onChange(1)
      else if (e.code === "Digit2") props.onChange(2)
      else if (e.code === "Digit3") props.onChange(3)
      else if (e.code === "Digit4") props.onChange(4)
      else if (e.code === "Digit5") props.onChange(5)
      else if (e.code === "Digit6") props.onChange(6)
      else if (e.code === "Digit7") props.onChange(7)
      else if (e.code === "Digit8") props.onChange(8)
      else if (e.code === "Digit9") props.onChange(9)
      else if (e.key === "ArrowRight") {
        e.preventDefault()
        props.onRight()
      } else if (e.key === "ArrowLeft") {
        e.preventDefault()
        props.onLeft()
      } else if (e.key === "ArrowUp") {
        e.preventDefault()
        props.onUp()
      } else if (e.key === "ArrowDown") {
        e.preventDefault()
        props.onDown()
      } else if (e.key === "Backspace") {
        e.preventDefault()
        props.onChange(0)
      }
    }

    window.addEventListener("keydown", onKeyDown)

    return () => {
      window.removeEventListener("keydown", onKeyDown)
    }
  }, [])

  return (
    <div className="flex justify-center font-display w-full sm:items-center">
      <div className="relative w-full">
        {props.matrix.map((cols, index) => (
          <div className={`grid relative w-full grid-cols-9`} key={index}>
            {cols.map((cel) => (
              <Cel id={cel.id} key={cel.id} />
            ))}
          </div>
        ))}

        <div
          className={`grid ${
            !props.paused ? "hidden" : ""
          } absolute inset-0 grid-cols-9 bg-white  `}
        >
          {Array.from({ length: 9 * 9 }).map((v, index) => (
            <div
              key={index}
              className={`border-t border-r border-slate-200  `}
              style={{ paddingTop: "100%" }}
            ></div>
          ))}
          <div
            className="absolute inset-0 flex items-center justify-center cursor-pointer"
            onClick={() => props.onResume()}
          >
            <div className="flex items-center justify-center p-3 transition-all duration-150 ease-in-out transform bg-blue-500 rounded-full hover:scale-110">
              <PlayCircleIcon className="w-8 h-8 text-white" />
            </div>
          </div>
        </div>

        <div
          className={`absolute inset-0 grid h-full w-full grid-cols-3 pointer-events-none`}
        >
          <div
            className="border border-t-2 border-l-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-t-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-t-2 border-r-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-l-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-r-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-b-2 border-l-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-b-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
          <div
            className="border border-b-2 border-r-2 border-slate-800  "
            style={{ paddingTop: "100%" }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export const GridContainer: React.FC<ContainerProps> = (props) => (
  <GridWrapper {...props} />
)

export const Grid = connector(GridContainer)
