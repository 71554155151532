import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState) => ({
  level: state.points.level,
  points: state.points.points - state.points.beforeLevelPoints,
  nextLevelPoints: state.points.nextLevelPoints,
  user: state.auth.user,
})

const mapDispatch = (dispatch: any) => ({})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
