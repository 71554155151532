import React, { useEffect } from "react"
import {
  connector,
  ContainerProps,
} from "./containers/LeaderboardOnPlayPage.container"
import { UserEntity } from "../../entities/UserEntity"
import CheckIcon from "@heroicons/react/20/solid/CheckIcon"
import {
  LeaderboardEntity,
  MetaLeaderboardEntity,
} from "../../entities/LeaderboardEntity"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { LeagueTimeout } from "../Leaderboard/components/LeagueTimeout/LeagueTimeout"
import { LeagueBadge } from "../Leaderboard/components/LeagueBadge/LeagueBadge"
import { LeagueUsersRanking } from "../Leaderboard/components/LeagueUsersRanking/LeagueUsersRanking"
import clsx from "clsx"
import { leaguesThreshold } from "../../constants/league-threshold"

const leagues: number[] = [
  0, 1, 2, 3, 4,
  // "ruby",
  // "saphir",
  // "emerald",
  // "master",
]

export const Wrapper: React.FC<{
  fetching: boolean
  users: LeaderboardEntity[]
  userId: UserEntity["id"] | null
  meta: MetaLeaderboardEntity | null
  noBorder?: boolean
  heightFull?: boolean
  onMount: () => void
}> = (props) => {
  useEffect(() => {
    props.onMount()
  }, [])

  const actualLeague = props.users[0]?.league || 0

  return (
    <div
      className={clsx(
        "relative flex flex-col border bg-white border-slate-100 rounded-md mt-2 overflow-auto",
        props.noBorder && "border-transparent",
        !props.fetching && props.users.length === 0 && !props.userId
          ? "pb-4"
          : props.heightFull
          ? "h-full"
          : "h-96"
      )}
    >
      <div className="w-full bg-white flex-shrink-0">
        <div className="pt-4">
          <div className="flex items-center justify-between px-4">
            <div className="font-display text-2xl leading-tight text-slate-900 font-semibold tracking-tighter">
              <FormattedMessage
                // @ts-ignore
                id={`leaderboard/league/title/${actualLeague}`}
              />
            </div>

            <LeagueTimeout />
          </div>

          <p className="text-sm px-4 text-slate-700">
            {actualLeague === 0 ? (
              <FormattedMessage
                id={`leaderboard/league/promotion/description/bronze`}
                values={{
                  v: <strong>{leaguesThreshold[0]}</strong>,
                }}
              />
            ) : actualLeague === 4 ? (
              <FormattedMessage
                id={`leaderboard/league/promotion/description/top`}
                values={{
                  b: <strong>{leaguesThreshold[actualLeague - 1]}</strong>,
                }}
              />
            ) : (
              <FormattedMessage
                id={`leaderboard/league/promotion/description/all`}
                values={{
                  v: <strong>{leaguesThreshold[actualLeague]}</strong>,
                  b: <strong>{leaguesThreshold[actualLeague - 1]}</strong>,
                }}
              />
            )}
          </p>

          <ul className="flex relative mt-4 items-center no-scroll-bar overflow-auto  justify-start px-4">
            {leagues.map((league, index) => (
              <li
                key={league}
                className="mb-2 mr-2 flex-nowrap flex-shrink-0 last:mr-0 capitalize"
              >
                <LeagueBadge league={league} disabled={index > actualLeague}>
                  <FormattedMessage
                    // @ts-ignore
                    id={`leaderboard/league/name/${league}`}
                  />
                </LeagueBadge>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="overflow-y-auto overflow-x-hidden h-full">
        <LeagueUsersRanking
          fluid
          small
          users={props.users}
          userId={props.userId}
        />

        {props.fetching && (
          <>
            {Array.from({ length: 4 }).map((e, index) => (
              <div
                key={index}
                style={{
                  animationDelay: `${(index + 1) * 100}ms`,
                  animationDuration: "1000ms",
                }}
                className="w-full h-12 mt-2 bg-slate-200 animate-pulse"
              ></div>
            ))}
          </>
        )}

        {!props.fetching && props.users.length === 0 && !props.userId && (
          <p className="text-xs mt-2 font-display font-semibold px-4 text-blue-500">
            <FormattedMessage id={`feature/auth/warning`} />
          </p>
        )}
      </div>
    </div>
  )
}

export const LockedModalContainer: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const LeaderboardOnPlayPage = connector(LockedModalContainer)
