import clsx from "clsx"
import React from "react"
import { ReactElement } from "react-markdown/lib/react-markdown"
import { ChestEntity } from "../../entities/ChestEntity"
import { secondsToHumanString } from "../../utils/date"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"

export const LeaderboardUserItem: React.FC<{
  isTheUser: boolean
  avatar: string
  position: number
  points?: number
  duration?: number
  chest?: ChestEntity["type"] | false
  fluid?: boolean
  username: string | ReactElement
  small?: boolean
}> = (props) => (
  <div
    className={clsx(
      "flex font-display items-center py-3 mt-2",
      props.small ? "pl-2 pr-4" : "sm:py-4 px-4",
      props.isTheUser ? "bg-slate-800" : "bg-slate-50",
      props.fluid ? "" : "rounded-md"
    )}
  >
    <div className="relative inline-block">
      <img
        src={`/avatars/${props.avatar || "dogs/16.png"}`}
        alt={props.avatar || "dogs/16.png"}
        className={`block rounded-full ${
          props.small ? "w-11 h-11 ml-1" : "w-14 h-14"
        }`}
      />

      {!props.chest && (
        <div
          className={`absolute bottom-0 left-0 flex items-center justify-center w-6 h-6 text-xs transform border-2 rounded-full -translate-x-1/4 ${
            props.isTheUser
              ? "bg-white border-slate-800  "
              : "  border-slate-50 bg-white"
          }`}
        >
          {props.position}
        </div>
      )}

      {props.chest && (
        <div
          className={clsx(
            `absolute bottom-0 left-0 flex items-center justify-center w-6 h-6 transform -translate-x-1/4`
          )}
        >
          <img
            width={props.small ? "20px" : "24px"}
            height={props.small ? "20px" : "24px"}
            src={`/chest/${props.chest}/icon.svg`}
            alt="chest"
          />
        </div>
      )}
    </div>
    <div className={`${props.small ? "ml-2" : "ml-4"}`}>
      <div
        className={`${
          props.small ? "text-xs" : "text-sm"
        } font-semibold uppercase ${
          props.isTheUser ? "text-slate-400" : "text-slate-500  "
        }`}
      >
        <FormattedMessage id="leaderboard/username" />
      </div>
      <div
        className={`break-words ${props.small ? "text-base" : "sm:text-lg"} ${
          props.isTheUser ? "text-slate-100  " : "text-slate-800  "
        }`}
      >
        {props.username}
      </div>
    </div>
    <div className="ml-auto text-right">
      <div
        className={`${
          props.small ? "text-xs" : "text-sm"
        } font-semibold uppercase ${
          props.isTheUser ? "text-slate-400  " : "text-slate-500  "
        }`}
      >
        {props.hasOwnProperty("duration") ? (
          <FormattedMessage id="daily.item.time" />
        ) : (
          <FormattedMessage id="leaderboard/points" />
        )}
      </div>
      <div
        className={`${
          props.small ? "text-sm" : "text-sm sm:text-base"
        } font-semibold text-blue-500 `}
      >
        {props.hasOwnProperty("duration") &&
          secondsToHumanString(props.duration)}
        {!props.hasOwnProperty("duration") && props.points}
      </div>
    </div>
  </div>
)
