import { connect, ConnectedProps } from "react-redux"
import { CelEntity, MatrixEntity } from "../../../entities/GameEntity"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (
  state: RootState,
  props: { id: string; readOnly?: boolean }
) => {
  const isSelected = props.id === state.play.selected
  const [row, cel, square] = props.id.split(":")
  const matrix = state.play.matrix as MatrixEntity
  const actual = matrix[row][cel] as CelEntity

  return {
    number: actual.number,
    selected: isSelected,
    default: actual.default,
    highlight: actual.highlight,
    drafts: actual.drafts,
    readOnly: props.readOnly,
    error: state.play.showErrors && actual.number !== actual.solution,
  }
}

const mapDispatch = (
  dispatch: any,
  props: { id: string; readOnly?: boolean }
) => ({
  onChange: (value: number) => {
    !props.readOnly &&
      dispatch(
        actions.play.$changeCel({
          value: value ? Number(value) : value,
        })
      )
  },
  onClick: () => {
    !props.readOnly &&
      dispatch(
        actions.play.selectCel({
          id: props.id,
        })
      )
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
