import { connect, ConnectedProps } from "react-redux"
import {
  DAILY_SMALL_CHEST_ID,
  SUBSCRIPTION_ITEM_ID,
} from "../../../database/marketplace-items"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props: { lang: string }) => {
  return {
    dark: state.darkMode.dark,
    lang: props.lang,
    stats: state.play.stats,
    isAuthenticated: state.auth.authenticated,
    isOpen: state.achievements.achieved.length === 0 && state.play.win,
    dailyChestIsOpen: state.marketplace.items.has(DAILY_SMALL_CHEST_ID),
    isPremium: state.pricing.isPremium,
  }
}

const mapDispatch = (dispatch: any) => ({
  onSubmit: () => {
    dispatch(actions.play.$selectGame())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
