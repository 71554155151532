import React, { useState } from "react"
import { FormattedMessage } from "../../FormattedMessage/FormattedMessage"

export const DailyChest = () => {
  return (
    <div className="bg-white max-w-xl mx-auto">
      <div className="p-4 pt-8">
        <div className="mx-auto">
          <img
            src={`/chest/silver/closed.svg`}
            width={"90px"}
            height={"90px"}
            alt="chest icon"
            className="mx-auto"
          />
        </div>

        <p className="font-display mt-6 font-medium text-xl text-center">
          <FormattedMessage id="success/daily-chest/title" />
        </p>
        <p className="text-center mt-2 text-slate-700">
          <FormattedMessage id="success/daily-chest/description" />
        </p>
      </div>
    </div>
  )
}
