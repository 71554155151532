import React from "react"
import {
  connector,
  ContainerProps,
} from "./containers/MarketplaceSubscribeOnPlayPageCTA.container"
import { navigate } from "gatsby"
import { SUBSCRIPTION_MODAL_HASH } from "../../utils/drawerUtils"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"

type Props = {
  subscribed: boolean
}

export const Wrapper: React.FC<Props> = (props) => {
  return props.subscribed ? (
    <></>
  ) : (
    <div className="mt-2">
      <div className="px-4 py-8 relative bg-slate-900 overflow-hidden bg-gradient-to-t from-slate-900 to-violet-900  w-full rounded-md items-center">
        <div
          className="absolute top-0 bottom-0 left-0 right-0  w-full transform -translate-x-1/2 -translate-y-20 bg-white rounded-br-full pointer-events-none select-none opacity-5 rotate-12"
          style={{ height: "600px" }}
        ></div>

        <img
          src="/marketplace/sparkle.svg"
          width="20px"
          height="20px"
          className="absolute left-4 top-4"
        />

        <img
          src="/marketplace/sparkle.svg"
          width="24px"
          height="24px"
          className="absolute right-4 top-10"
        />

        <img
          src="/marketplace/sparkle.svg"
          width="20px"
          height="20px"
          className="absolute right-8 bottom-6"
        />

        <img
          src="/marketplace/sparkle.svg"
          width="20px"
          height="20px"
          className="absolute top-6 left-96"
        />
        <img
          src="/marketplace/sparkle.svg"
          width="24px"
          height="24px"
          className="absolute bottom-6 right-80"
        />

        <img
          src="/marketplace/sparkle.svg"
          width="24px"
          height="24px"
          className="absolute left-8 bottom-5"
        />

        <img
          src="/marketplace/sparkle.svg"
          width="24px"
          height="24px"
          className="absolute left-60 bottom-8"
        />

        <div className="rounded flex-shrink-0 relative ">
          <img
            width={"96px"}
            height={"96px"}
            className="w-24 h-24 mx-auto rounded"
            src={"/chest/ultimate/open.svg"}
          />
        </div>
        <div className="text-center mt-4 relative ">
          <p className="font-display text-white text-center font-semibold text-xl">
            <FormattedMessage id="premium/call-to-action/title" />
          </p>
          <p className=" text-slate-50 text-center">
            <FormattedMessage id="premium/call-to-action/description" />
          </p>
        </div>
        <div className="flex-shrink-0 ml-auto text-center mt-4 relative ">
          <button
            onClick={() => {
              navigate(location.pathname + "#" + SUBSCRIPTION_MODAL_HASH)
            }}
            className="bg-white outline-none mt-2 rounded-xl transition-all ease-in-out duration-300 font-display font-semibold p-4 shadow-btn-2 text-violet-500 shadow-violet-500 border-2 border-violet-500"
          >
            <FormattedMessage id="premium/call-to-action/more" />
          </button>
        </div>
      </div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const MarketplaceSubscribeOnPlayPageCTA = connector(Container)
