import React, { ReactNode } from "react"
import { Transition } from "@headlessui/react"
import { ReactElement } from "react-markdown/lib/react-markdown"

export const Stats: React.FC<{
  children: ReactNode | ReactElement | string
}> = ({ children }) => (
  <Transition
    appear={true}
    enter="transition-all duration-500 ease-in-out transform"
    enterFrom="opacity-0 -translate-y-3"
    enterTo="opacity-100 translate-y-0"
  >
    <div className="grid w-full grid-cols-2 gap-y-4">{children}</div>
  </Transition>
)

export const Stat: React.FC<{ label: string; value: string | number }> = ({
  label,
  value,
}) => (
  <div>
    <div className="text-xs font-display font-bold text-white uppercase opacity-70">
      {label}
    </div>
    <div className="font-medium text-white">{value}</div>
  </div>
)
