import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState) => ({
  canBuyHeart: state.coins.amount > 0,
})

const mapDispatch = (dispatch: any) => ({
  onAfterSeeAds: () => {
    dispatch(actions.play.$recoverOneLifeWithAds())
  },
  spendCoinForOneHeart: () => {
    dispatch(actions.play.$recoverOneLifeWithCoins())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
