import React, { ReactElement } from "react"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import {
  connector,
  ContainerProps,
} from "../Controls/containers/Controls.containers"

type Props = {
  onClick: Function
  onDraft: Function
  onUndo: Function
  onClue: Function
  remainingClues: number
  onErase: Function
  level: string
  isInDraftMode: boolean
}

const Key = ({ active, value, onClick }) => (
  <div
    onClick={() => active && onClick()}
    className={`h-16 border  rounded border-slate-200 font-display w-full bg-white items-center justify-center text-2xl cursor-pointer text-slate-700 ${
      !active ? "hidden" : "flex"
    }`}
  >
    {value}
  </div>
)

type ControlType = {
  icon: ReactElement
  onClick: () => void
  badge?: number
  isActive?: boolean
  isDisabled?: boolean
}

const Control: React.FC<ControlType> = ({
  icon,
  onClick = () => false,
  isActive = false,
  badge,
  isDisabled,
}) => (
  <div
    className={`h-16 w-full flex rounded font-display border border-slate-200 items-center justify-center transform transition duration-300 ease-in-out   ${
      isActive ? "bg-blue-50 shadow-2xl relative z-10 ring-1 scale-110" : ""
    } ${
      isDisabled
        ? "cursor-not-allowed bg-slate-200 text-slate-500"
        : "bg-white hover:bg-blue-50 cursor-pointer text-slate-700  "
    } border-slate-300 relative`}
    onClick={() => onClick()}
  >
    <div>
      <div className="flex justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          {icon}
        </svg>
      </div>
    </div>
    {badge && badge > 0 ? (
      <div className="absolute flex items-center justify-center w-5 h-5 text-xs text-white bg-slate-400 rounded pointer-events-none top-1 right-1">
        {badge}
      </div>
    ) : (
      <></>
    )}
  </div>
)

export const ControlsMobileWrapper: React.FC<Props> = (props) => {
  return (
    <div>
      <div className="grid grid-cols-4 gap-1">
        <Control
          onClick={() => props.onUndo()}
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z"
            />
          }
        />
        <Control
          onClick={() => props.onDraft()}
          isActive={props.isInDraftMode}
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          }
        />
        <Control
          onClick={() => props.onClue()}
          badge={props.remainingClues}
          isDisabled={props.remainingClues <= 0}
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
            />
          }
        />
        <Control
          onClick={() => props.onErase()}
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z"
            />
          }
        />
      </div>
      <div className="grid grid-cols-9 gap-1 mt-1">
        {Array.from({ length: 9 }).map((v, value) => (
          <Key
            value={value + 1}
            onClick={() => props.onClick(value + 1, props.isInDraftMode)}
            key={value + 1}
            active
          />
        ))}
      </div>
    </div>
  )
}

export const ControlsMobileContainer: React.FC<ContainerProps> = (props) => (
  <ControlsMobileWrapper {...props} />
)

export const ControlsMobile = connector(ControlsMobileContainer)
