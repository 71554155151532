import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState) => ({
  type: state.points.promotionModal.type,
  actualLeague: state.points.promotionModal.actualLeague,
  lastLeague: state.points.promotionModal.lastLeague,
})

const mapDispatch = (dispatch: any) => ({})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
