import ArrowRightIcon from "@heroicons/react/20/solid/ArrowRightIcon"
import React from "react"
import { StatForShareableLink } from "../../../entities/StatEntity"
import { FormattedMessage } from "../../FormattedMessage/FormattedMessage"

const getBackgroundColorForBadge = (level: StatForShareableLink["level"]) => {
  if (level === "medium") return "bg-yellow-600 hover:bg-yellow-700"
  if (level === "hard") return "bg-red-600 hover:bg-red-700"
  if (level === "expert") return "bg-slate-600 hover:bg-slate-700"
  if (level === "evil") return "bg-black hover:bg-slate-800"

  return "bg-blue-500 hover:bg-blue-600"
}

export const Next: React.FC<{
  onNext: Function
  level: StatForShareableLink["level"]
}> = (props) => {
  return (
    <button
      onClick={() => props.onNext()}
      className={`flex items-center font-display focus:outline-none text-lg font-medium text-center outline-none justify-center w-60 py-3 text-white duration-300 rounded-md border-2 border-blue-700 shadow-btn-2 shadow-blue-700 cursor-pointer ${getBackgroundColorForBadge(
        props.level
      )}`}
    >
      <FormattedMessage id="play.success.next" />
      <ArrowRightIcon className="w-6 h-6 ml-4" />
    </button>
  )
}
