import React from "react"
import dayjs from "dayjs"
import { MetaLeaderboardEntity } from "../../../../entities/LeaderboardEntity"
import { connector, ContainerProps } from "./containers/LeagueTimeout.container"
import { getTimeout } from "../../../../utils/date"

export const Wrapper: React.FC<{
  meta: MetaLeaderboardEntity | null
}> = (props) => {
  return (
    <p className="text-yellow-500 font-medium font-display text-center">
      {getTimeout(props.meta?.end || new Date())}
    </p>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const LeagueTimeout = connector(Container)
