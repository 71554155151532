import React, { useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon"
import {
  connector,
  ContainerProps,
} from "./containers/AchievementsModal.container"
import { AchievementWithRewardEntity } from "../../entities/AchievementEntity"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { ITranslations } from "../../interfaces/ITranslations"

type Props = {
  isOpen: boolean
  onClose: () => void
  achievements: AchievementWithRewardEntity[]
}

const Modal: React.FC<{
  show: boolean
  onSubmit: () => void
  achievement: AchievementWithRewardEntity
}> = (props) => (
  <Transition
    as={"div"}
    show={props.show}
    className="fixed inset-0 z-20 flex items-center justify-center w-full h-full transform"
    enter="ease-in-out duration-500"
    enterFrom="opacity-0 translate-x-1/4"
    enterTo="opacity-100 translate-x-0"
    leave="ease-in-out duration-500"
    leaveFrom="opacity-100 translate-x-0"
    leaveTo="opacity-0 -translate-x-1/4"
  >
    <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-2xl sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
      <div>
        <div className="mt-3 text-center">
          <h3 className="text-sm font-bold text-slate-500 uppercase">
            <FormattedMessage id="achievements.modal.title" />
          </h3>

          <img
            src={`/avatars/${props.achievement.reward}`}
            className="w-24 h-24 mx-auto mt-6"
          />

          <div className="mt-4">
            <p className="font-medium text-slate-700 text-md">
              <FormattedMessage id="achievements.modal.congrats" />
              <br />
              <FormattedMessage id="achievements.modal.description" />
            </p>

            <p className="mt-4 text-sm italic text-slate-500">
              <FormattedMessage
                id={props.achievement.id as ITranslations["keys"]}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div className=" group">
          <div
            onClick={() => props.onSubmit()}
            className={`transform w-full relative z-10 group flex cursor-pointer items-center uppercase h-11 bg-blue-500 text-white transition-all duration-300 ease-in-out group-hover:bg-blue-600 rounded text-sm font-semibold justify-center`}
          >
            <FormattedMessage id="achievements.modal.next" />
            <ArrowRightIcon className="w-5 h-5 ml-2" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
)

export const Wrapper: React.FC<Props> = (props) => {
  const [actual, setActual] = useState(0)

  useEffect(() => {
    setActual(0)
  }, [props.isOpen])

  return (
    <Transition.Root show={props.isOpen} as={React.Fragment}>
      <Dialog as={"div"} onClose={() => false}>
        <Transition.Child
          as={"div"}
          className="fixed inset-0 z-20 transition-opacity"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-0 bg-white opacity-90"></div>
        </Transition.Child>
        <>
          {props.achievements.map((achievement, index) => (
            <Modal
              key={achievement.id}
              show={actual === index}
              achievement={achievement}
              onSubmit={() => {
                if (props.achievements.length >= index + 1) {
                  return props.onClose()
                } else {
                  return setActual(index + 1)
                }
              }}
            />
          ))}
        </>
      </Dialog>
    </Transition.Root>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const AchievementsModal = connector(Container)
