import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { useLocation } from "@reach/router"
import { Confetti } from "../Confetti/Confetti"
import { LeagueBadge } from "../Leaderboard/components/LeagueBadge/LeagueBadge"
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon"
import {
  connector,
  ContainerProps,
} from "./containers/LeaguePromotionModal.container"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { ArrowLeftIcon } from "@heroicons/react/24/outline"
import { navigate } from "gatsby"

export type Props = {
  actualLeague: number
  lastLeague: number
  type: "promotion" | "relegation" | "egation"
}

export const Wrapper: React.FC<Props> = (props) => {
  const location = useLocation()

  const isOpen = location.href?.includes("league-promotion-modal=open") || false

  const onClose = () => navigate(-1)

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className={`fixed inset-0 z-10 overflow-y-auto`}
          onClose={onClose}
        >
          {props.type === "promotion" && <Confetti respawn show />}

          <div className="min-h-screen p-2 sm:block">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-slate-500 bg-opacity-75 " />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full justify-center items-center p-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="relative max-w-xl mx-auto rounded-lg shadow-lg">
                    <div className="bg-white rounded-lg shadow-xs">
                      <div className="pt-5 pb-6">
                        <div className="flex px-6 items-center justify-between">
                          <p className="font-display text-2xl font-bold">
                            <FormattedMessage
                              id={`leaderboard/promotion-modal/${props.type}/title`}
                            />
                          </p>
                          <div className="-mr-2">
                            <button
                              type="button"
                              onClick={onClose}
                              className="inline-flex items-center justify-center p-2 text-slate-400 transition duration-150 ease-in-out rounded-md   hover:text-slate-500  :bg-slate-900    :text-slate-100 hover:bg-slate-100 focus:outline-none focus:bg-slate-100 focus:text-slate-500"
                            >
                              <svg
                                className="w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>

                        <div className="px-6">
                          <FormattedMessage
                            id={`leaderboard/promotion-modal/${props.type}/description`}
                          />

                          <div className="flex mt-4 items-center">
                            {props.type === "egation" && (
                              <>
                                <LeagueBadge big league={props.lastLeague}>
                                  <FormattedMessage
                                    id={`leaderboard/league/name/${props.lastLeague}`}
                                  />
                                </LeagueBadge>
                              </>
                            )}
                            {props.type === "relegation" && (
                              <>
                                <LeagueBadge big league={props.actualLeague}>
                                  <FormattedMessage
                                    id={`leaderboard/league/name/${props.actualLeague}`}
                                  />
                                </LeagueBadge>
                                <div className="px-6 text-slate-400">
                                  <ArrowLeftIcon className="h-6 w-6 animate-bounceHorizontal" />
                                </div>
                                <LeagueBadge
                                  big
                                  league={props.lastLeague}
                                  disabled
                                >
                                  <FormattedMessage
                                    id={`leaderboard/league/name/${props.lastLeague}`}
                                  />
                                </LeagueBadge>
                              </>
                            )}
                            {props.type === "promotion" && (
                              <>
                                <LeagueBadge
                                  disabled
                                  big
                                  league={props.lastLeague}
                                >
                                  <FormattedMessage
                                    id={`leaderboard/league/name/${props.lastLeague}`}
                                  />
                                </LeagueBadge>
                                <div className="px-6 text-slate-400">
                                  <ArrowRightIcon className="h-6 w-6 animate-bounceHorizontal" />
                                </div>
                                <LeagueBadge big league={props.actualLeague}>
                                  <FormattedMessage
                                    id={`leaderboard/league/name/${props.actualLeague}`}
                                  />
                                </LeagueBadge>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const LeaguePromotionModal = connector(Container)
