import React, { useEffect } from "react"
import PauseIcon from "@heroicons/react/20/solid/PauseCircleIcon"
import PlayIcon from "@heroicons/react/20/solid/PlayCircleIcon"
import { connector, ContainerProps } from "./containers/Timer.containers"

export const TimerWrapper: React.FC<{
  paused: boolean
  time: number
  onTick: () => void
  onResume: () => void
  onPause: () => void
}> = (props) => {
  useEffect(() => {
    const pauseEvent = () => props.onPause()
    const tickInterval = setInterval(() => props.onTick(), 1000)

    window.addEventListener("blur", pauseEvent)

    return () => {
      clearInterval(tickInterval)
      window.removeEventListener("blur", pauseEvent)
    }
  }, [])

  const minutes = props.time / 60 >= 1 ? Math.floor(props.time / 60) : 0
  const seconds =
    props.time % 60 < 10 ? "0" + (props.time % 60) : props.time % 60

  const displayMinutes = minutes < 10 ? "0" + minutes : minutes

  return (
    <div
      onClick={() => {
        props.paused ? props.onResume() : props.onPause()
      }}
      className="flex items-center font-display justify-end w-20 py-2 ml-auto font-medium text-slate-400 transition duration-300 ease-in-out rounded cursor-pointer     hover:bg-blue-50 hover:text-blue-500  :bg-slate-700"
    >
      <div>
        {displayMinutes}:{seconds}
      </div>
      <div className="flex-shrink-0">
        {!props.paused && <PauseIcon className="w-5 h-5 ml-1" />}
        {props.paused && <PlayIcon className="w-5 h-5 ml-1" />}
      </div>
    </div>
  )
}

export const TimerContainer: React.FC<ContainerProps> = (props) => (
  <TimerWrapper {...props} />
)

export const Timer = connector(TimerContainer)
