import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState) => ({
  achievements: state.achievements.achieved,
  isOpen: state.achievements.achieved.length > 0,
})

const mapDispatch = (dispatch: any) => ({
  onClose: () => {
    dispatch(actions.achievements.clearAchieved())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
