import clsx from "clsx"
import React from "react"

export const LeagueBadge: React.FC<{
  children: React.ReactNode
  disabled?: boolean
  league: number
  big?: boolean
}> = (props) => {
  return (
    <div
      className={clsx(
        props.big ? "h-10 text-sm px-4" : "h-8 text-xs px-3",
        `inline-flex rounded-lg border-b-2 items-center   font-display font-medium uppercase`,

        props.league === 0 &&
          !props.disabled &&
          "text-amber-50 bg-amber-600 border-amber-700",
        props.league === 1 &&
          !props.disabled &&
          "text-zinc-50 bg-zinc-400 border-zinc-500",
        props.league === 2 &&
          !props.disabled &&
          "text-yellow-50 bg-yellow-400 border-yellow-500",
        props.league === 3 &&
          !props.disabled &&
          "text-stone-50 bg-stone-700 border-stone-800",
        props.league === 4 &&
          !props.disabled &&
          "text-blue-50 bg-blue-400 border-blue-500",
        // props.league === "ruby" &&
        //   !props.disabled &&
        //   "text-red-50 bg-red-400 border-red-500",
        // props.league === "saphir" &&
        //   !props.disabled &&
        //   "text-indigo-50 bg-indigo-400 border-indigo-500",
        // props.league === "emerald" &&
        //   !props.disabled &&
        //   "text-emerald-50 bg-emerald-400 border-emerald-500",
        // props.league === "master" && !props.disabled && "text-purple-50 bg-purple-400 border-purple-500",

        props.disabled &&
          "text-slate-700 bg-slate-200 border-slate-300 opacity-40 cursor-not-allowed"
      )}
    >
      {props.children}
    </div>
  )
}
