import React from "react"
import { Transition } from "@headlessui/react";
import { StatForShareableLink } from "../../../entities/StatEntity";
import { ReactComponent as Star } from "../../../assets/badges/star.svg"

export const Stars: React.FC<{ stats: StatForShareableLink }> = (props) => (
    <div className="flex justify-center mt-6">
      <div className="flex items-end mx-auto">
        <Transition
          appear={true}
          enter="delay-500 transition-all duration-500 ease-in-out transform"
          enterFrom="opacity-0 translate-y-5"
          enterTo="opacity-100 translate-y-0"
        >
          <Star className="w-16 h-16" />
        </Transition>
  
        <Transition
          appear={true}
          enter="delay-1000 transition-all duration-500 ease-in-out transform"
          enterFrom="opacity-0 translate-y-5"
          enterTo="opacity-100 translate-y-0"
        >
          <Star
            className="w-20 h-20"
            fillOpacity={
              props.stats.errors === 0 && props.stats.clues === 0 ? "1" : "0.2"
            }
            strokeOpacity={
              props.stats.errors === 0 && props.stats.clues === 0 ? "1" : "0.5"
            }
          />
        </Transition>
  
        <Transition
          appear={true}
          enter="delay-700 transition-all duration-500 ease-in-out transform"
          enterFrom="opacity-0 translate-y-5"
          enterTo="opacity-100 translate-y-0"
        >
          <Star
            className="w-16 h-16"
            fillOpacity={
              props.stats.errors === 0 || props.stats.clues === 0 ? "1" : "0.2"
            }
            strokeOpacity={
              props.stats.errors === 0 || props.stats.clues === 0 ? "1" : "0.5"
            }
          />
        </Transition>
      </div>
    </div>
  )