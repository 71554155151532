import ArrowRightIcon from "@heroicons/react/20/solid/ArrowRightIcon"
import React, { useEffect, useState } from "react"
import { FormattedMessage } from "../../FormattedMessage/FormattedMessage"

export const NextWithAds: React.FC<{
  onNext: Function
}> = (props) => {
  const [timeOut, setTimeOut] = useState(10)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeOut((timeOut) => {
        if (timeOut <= 0) {
          clearInterval(interval)
          return 0
        }
        return timeOut - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return timeOut <= 0 ? (
    <button
      onClick={() => props.onNext()}
      className={`flex items-center bg-blue-500 hover:bg-blue-600 font-display focus:outline-none text-lg font-medium text-center outline-none justify-center w-60 py-3 text-white duration-300 rounded-md border-2 border-blue-700 shadow-btn-2 shadow-blue-700 cursor-pointer`}
    >
      <FormattedMessage id="play.success.next" />
      <ArrowRightIcon className="w-6 h-6 ml-4" />
    </button>
  ) : (
    <button
      className={`flex items-center bg-slate-200 font-display focus:outline-none text-lg font-medium text-center outline-none justify-center px-6 py-3 text-slate-500 duration-300 rounded-md border-2 border-slate-300 cursor-not-allowed`}
    >
      <FormattedMessage id="success/ads/timeout" /> ({timeOut}s)
    </button>
  )
}
