import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../../../redux/store"

const mapState = (state: RootState, props: { onNext: Function }) => {
  return {
    stats: state.play.stats,
    points: state.points.points - state.points.beforeLevelPoints,
    nextLevelPoints: state.points.nextLevelPoints,
    level: state.points.level,
    steps: state.points.steps,
    levelUp: state.points.before.level < state.points.level,
    totalPointsWon: state.points.points - state.points.before.points,
    onNext: props.onNext,
  }
}

const mapDispatch = (dispatch: any) => ({})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
