import React from "react"
import { Link } from "gatsby"
import ExclamationIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon"
import LoginIcon from "@heroicons/react/24/outline/ArrowRightOnRectangleIcon"
import { StatForShareableLink } from "../../../entities/StatEntity"
import { Badge } from "./Badge"
import { connector, ContainerProps } from "./containers/Auth.containers"
import { FormattedMessage } from "../../FormattedMessage/FormattedMessage"
import { getSigninUrl } from "../../../i18n/utils/getUrl"

export const Wrapper: React.FC<{
  stats: StatForShareableLink
  lang: string
}> = (props) => {
  return (
    <Badge>
      <ExclamationIcon className="w-10 h-10 mx-auto text-white" />

      <div className="mt-2 text-white">
        <FormattedMessage id="play.success.signin.description" />
      </div>

      <div className="w-full font-display mx-auto mt-4 space-y-2 text-lg font-medium text-center">
        <Link
          to={getSigninUrl(props.lang)}
          className={`flex items-center justify-center w-full py-5 text-slate-800 duration-300 rounded-md cursor-pointer hover:shadow-sm transition hover:bg-slate-50 ease-in-out bg-white shadow-xl`}
        >
          <FormattedMessage id="play.success.signin.button" />
          <LoginIcon className="w-5 h-5 ml-4" />
        </Link>
      </div>
    </Badge>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Auth = connector(Container)
