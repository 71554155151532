import React, { useEffect, useState } from "react"
import { ContainerProps, connector } from "./containers/Success.containers"
import { Dialog, Transition } from "@headlessui/react"

import { StatForShareableLink } from "../../entities/StatEntity"

import { Level } from "./components/Level"
import { Share } from "./components/Share"
import { Info } from "./components/Info"
import { Auth } from "./components/Auth"
import { LeaderboardOnPlayPage } from "../LeaderboardOnPlayPage/LeaderboardOnPlayPage"
import { Next } from "./components/Next"
import { DailyChest } from "./components/DailyChest"
import { ChestExperienceRewardOnPlayPage } from "../ChestExperienceRewardOnPlayPage/ChestExperienceRewardOnPlayPage"
import { Confetti } from "../Confetti/Confetti"
import { ReactElement } from "react-markdown/lib/react-markdown"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { HeartBonus } from "./components/HeartBonus"
import { NextWithAds } from "./components/NextWithAds"
import { Ads, AdsTypes } from "../Ads/Ads"
import { MarketplaceSubscribeOnPlayPageCTA } from "../MarketplaceSubscribeOnPlayPageCTA/MarketplaceSubscribeOnPlayPageCTA"

type Views =
  | "share"
  | "level"
  | "auth"
  | "leaderboard"
  | "daily-chest"
  | "weekly-chest-exp"
  | "ads"
  | "heart-bonus"

type Props = {
  onSubmit: Function
  isAuthenticated: boolean
  isOpen: boolean
  lang: string
  dark: boolean
  isPremium: boolean
  stats: StatForShareableLink
  dailyChestIsOpen: boolean
}

const totalInfoBlocks = 3

const InfoBlock: React.FC<{
  title: ReactElement | string
  description: ReactElement | string
}> = (props) => {
  return (
    <div className="text-center max-w-xl mx-auto bg-pink-50 rounded-xl p-8">
      <p className="text-pink-500 font-display font-semibold">
        <FormattedMessage id="success/tips/label" />
      </p>

      <p className="text-2xl font-display font-semibold">{props.title}</p>
      <p className="mt-2">{props.description}</p>
    </div>
  )
}

export const Wrapper: React.FC<Props> = (props) => {
  const [view, setView] = useState<Views>("level")
  const [info, setInfo] = useState<0 | 1 | 2 | 3>(0)
  const onChange = (view: Views) => {
    setView(view)
    refreshInfo()
  }

  const refreshInfo = () => {
    setInfo(Math.floor(Math.random() * (totalInfoBlocks + 1)) as 0 | 1 | 2 | 3)
  }

  useEffect(() => {
    setView("level")
    refreshInfo()
  }, [props.isOpen])

  return (
    <Transition.Root show={props.isOpen} as={React.Fragment}>
      <Dialog
        as={"div"}
        className={`${props.dark ? "dark" : ""}`}
        onClose={() => false}
      >
        <Transition.Child
          as={"div"}
          className="fixed inset-0 z-20 transition-opacity"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-0 bg-white opacity-100"></div>
        </Transition.Child>

        <Confetti show={props.isOpen} respawn={false} />

        <Transition.Child
          as="div"
          className="fixed inset-0 z-30 flex flex-col transform"
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-8"
          enterTo="opacity-100 translate-y-0"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-8 sm:translate-y-0"
        >
          <div className="h-full flex items-center justify-center grid-cols-2 md:grid w-full overflow-auto">
            <div className="md:border-r-2 w-full h-full border-slate-200 overflow-auto">
              {view === "level" && (
                <div className="flex h-full justify-center items-center">
                  <div className="max-w-xl">
                    <Level
                      onNext={() =>
                        onChange(props.isAuthenticated ? "leaderboard" : "auth")
                      }
                    />
                  </div>
                </div>
              )}
              {view === "auth" && (
                <div className="flex h-full justify-center items-center">
                  <Auth />
                </div>
              )}
              {view === "leaderboard" && (
                <div className="w-full">
                  <LeaderboardOnPlayPage heightFull noBorder />
                </div>
              )}
              {view === "daily-chest" && (
                <div className="flex h-full justify-center items-center">
                  <div>
                    <DailyChest />
                  </div>
                </div>
              )}
              {view === "ads" && (
                <div className="flex h-full items-center justify-center overflow-auto">
                  <div className="max-w-md m-auto w-full px-4">
                    <Ads type={AdsTypes.SQUARE} show headline />
                  </div>
                </div>
              )}
              {view === "weekly-chest-exp" && (
                <div className="flex h-full justify-center items-center">
                  <div className="max-w-xl w-full">
                    <ChestExperienceRewardOnPlayPage isSuccessPage />
                  </div>
                </div>
              )}

              {view === "heart-bonus" && (
                <div className="flex h-full justify-center items-center">
                  <div className="max-w-xl w-full">
                    <HeartBonus />
                  </div>
                </div>
              )}

              {view === "share" && (
                <div className="flex h-full justify-center items-center">
                  <div className="max-w-xl">
                    <Info stats={props.stats} />
                    <Share stats={props.stats} lang={props.lang} />
                  </div>
                </div>
              )}
            </div>

            <div className="hidden md:block">
              {info !== 3 && (
                <InfoBlock
                  title={<FormattedMessage id={`success/tips/${info}/title`} />}
                  description={
                    <FormattedMessage id={`success/tips/${info}/description`} />
                  }
                />
              )}

              {info === 3 && !props.isPremium && (
                <div className="max-w-md mx-auto">
                  <MarketplaceSubscribeOnPlayPageCTA />
                </div>
              )}

              {info === 3 && props.isPremium && (
                <InfoBlock
                  title={<FormattedMessage id={`success/tips/${1}/title`} />}
                  description={
                    <FormattedMessage id={`success/tips/${1}/description`} />
                  }
                />
              )}
            </div>
          </div>

          <div className="border-t-2 bg-white border-slate-200 flex justify-center px-4 py-4 flex-shrink-0 w-full">
            {view !== "ads" && (
              <Next
                level="easy"
                onNext={() => {
                  if (view === "level")
                    return onChange(
                      props.isAuthenticated ? "leaderboard" : "auth"
                    )

                  if (view === "auth") return onChange("share")
                  if (view === "leaderboard")
                    return onChange(
                      props.dailyChestIsOpen
                        ? "weekly-chest-exp"
                        : "daily-chest"
                    )
                  if (view === "daily-chest")
                    return onChange("weekly-chest-exp")
                  if (view === "weekly-chest-exp")
                    return onChange(
                      props.stats.errors > 0
                        ? "heart-bonus"
                        : !props.isPremium
                        ? "ads"
                        : "share"
                    )
                  if (view === "heart-bonus")
                    return onChange(props.isPremium ? "share" : "ads")

                  if (view === "share") return props.onSubmit()
                }}
              />
            )}

            {view === "ads" && (
              <NextWithAds
                onNext={() => {
                  if (view === "ads") return onChange("share")
                }}
              />
            )}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Success = connector(Container)
