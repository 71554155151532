import { navigate } from "gatsby"
import React, { ReactElement, useEffect } from "react"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { NewGameModal } from "../NewGameModal/NewGameModal"
import { connector, ContainerProps } from "./containers/Controls.containers"

const Key = ({ value, active, onClick }) => (
  <div
    className={`h-20 w-20 border border-slate-100 hover:bg-blue-50 hover:text-blue-500 cursor-pointer transition-all text-slate-900 duration-300 ease-in-out rounded flex items-center justify-center text-4xl`}
    key={value}
    onClick={() => active && onClick()}
  >
    {value}
  </div>
)

const Control: React.FC<{
  icon: ReactElement
  onClick: () => void
  badge?: number
  isActive?: boolean
  label: ReactElement
  isDisabled?: boolean
  hideLabels?: boolean
}> = ({
  icon,
  label,
  badge,
  isDisabled = false,
  isActive = false,
  onClick = () => false,
  hideLabels = false,
}) => (
  <div
    onClick={() => !isDisabled && onClick()}
    className={`${
      isActive
        ? "bg-blue-50 shadow-2xl relative z-10 ring-1 scale-105"
        : "border border-slate-100"
    } ${
      isDisabled
        ? "cursor-not-allowed bg-slate-200 text-slate-500    "
        : "bg-white hover:bg-blue-50 hover:text-blue-500 cursor-pointer"
    } relative transform transition-all rounded duration-150 ease-in-out h-16 w-full flex items-center justify-center text-slate-900  border-slate-200`}
  >
    <div>
      <div className="flex justify-center">{icon}</div>
      {!hideLabels && <div className="mt-1 text-xs text-center">{label}</div>}
      {badge && badge > 0 ? (
        <div className="absolute flex items-center justify-center w-5 h-5 text-xs text-white bg-slate-400 rounded pointer-events-none top-1 right-1">
          {badge}
        </div>
      ) : (
        <></>
      )}
    </div>
  </div>
)

type Props = {
  onClick: Function
  onDraft: Function
  onUndo: Function
  onErase: Function
  onClue: Function
  level: string
  remainingClues: number
  hideLabels?: boolean
  hideNewGame?: boolean
  showGiveUp?: boolean
  isInDraftMode: boolean
}

export const ControlsWrapper: React.FC<Props> = (props) => {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        e.preventDefault()
        props.onDraft()
      }
    }

    window.addEventListener("keydown", onKeyDown)

    return () => {
      window.removeEventListener("keydown", onKeyDown)
    }
  }, [])

  return (
    <div className="space-y-2 font-display">
      <NewGameModal />

      {!props.hideNewGame && (
        <div
          onClick={() =>
            navigate(window?.location.pathname + "#new-game-modal=open")
          }
          className="w-full py-5 font-medium text-center text-white uppercase transition duration-300 ease-in-out bg-blue-500 rounded cursor-pointer hover:bg-blue-600"
        >
          <FormattedMessage id="play.controls.new-game" />
        </div>
      )}

      <div className="grid grid-cols-2 gap-x-2">
        <Control
          onClick={() => props.onDraft()}
          isActive={props.isInDraftMode}
          hideLabels={props.hideLabels}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
          }
          label={<FormattedMessage id="play.controls.notes" />}
        />

        <Control
          onClick={() => props.onClue()}
          badge={props.remainingClues}
          isDisabled={props.remainingClues <= 0}
          hideLabels={props.hideLabels}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
              />
            </svg>
          }
          label={<FormattedMessage id="play.controls.clue" />}
        />
      </div>

      <div className="grid grid-cols-2 gap-x-2">
        <Control
          onClick={() => props.onUndo()}
          hideLabels={props.hideLabels}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
              />
            </svg>
          }
          label={<FormattedMessage id="play.controls.undo" />}
        />
        <Control
          onClick={() => props.onErase()}
          hideLabels={props.hideLabels}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z"
              />
            </svg>
          }
          label={<FormattedMessage id="play.controls.erase" />}
        />
      </div>

      <div className="grid grid-cols-3 gap-2">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => (
          <Key
            active
            value={value}
            key={value}
            onClick={() => props.onClick(value, props.isInDraftMode)}
          />
        ))}
      </div>
    </div>
  )
}

export const ControlsContainer: React.FC<ContainerProps> = (props) => (
  <ControlsWrapper {...props} />
)

export const Controls = connector(ControlsContainer)
