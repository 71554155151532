import React from "react"
import { ReactElement } from "react-markdown/lib/react-markdown"

import { StatForShareableLink } from "../../../entities/StatEntity"
import { connector, ContainerProps } from "./containers/Badge.containers"

const getBackgroundColorForBadge = (level: StatForShareableLink["level"]) => {
  if (level === "easy") return "bg-blue-500"
  if (level === "medium") return "bg-yellow-500"
  if (level === "hard") return "bg-red-500"
  if (level === "expert") return "bg-slate-600"
  if (level === "evil") return "bg-slate-900"

  return "bg-blue-500"
}

export const Wrapper: React.FC<{
  stats: StatForShareableLink
  children: ReactElement
}> = (props) => {
  return (
    <div
      className={`relative px-10 pt-10 pb-10 mx-auto overflow-hidden ${getBackgroundColorForBadge(
        props.stats.level
      )} rounded-xl w-80`}
    >
      {props.children}
      <div
        className="absolute top-0 bottom-0 left-0 right-0 z-10 w-full transform -translate-x-1/2 -translate-y-20 bg-white rounded-br-full pointer-events-none select-none opacity-10 rotate-12"
        style={{ height: "600px" }}
      ></div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Badge = connector(Container)
