import React from "react"
import { CelEntity } from "../../entities/GameEntity"
import { connector, ContainerProps } from "./containers/Cel.containers"

type Props = {
  onClick: Function
  error: boolean
  selected: boolean
  default: boolean
  number: number
  highlight: CelEntity["highlight"]
  drafts: Array<number | null>
  readOnly?: boolean
}

const getCelClass = (
  props: Pick<Props, "selected" | "highlight" | "default" | "error">
) => {
  const base = `${props.default ? "" : ""}`

  if (props.selected) {
    return `shadow-xl ring-2 z-10 scale-110 bg-white ${
      props.error || props.highlight === "error"
        ? "text-red-400"
        : "text-blue-500"
    }`
  }

  if (props.highlight === "error") return `bg-red-100 text-red-400 ${base}`

  if (props.highlight === "light-dark")
    return `bg-blue-200   ${props.default ? " " : "text-blue-500"} ${base}`

  if (props.highlight === "light")
    return `bg-blue-50   ${props.default ? " " : ""} ${base}`

  if (props.default) return `${base}`

  if (props.error) return `text-red-400 ${base}`

  return `${base}`
}

export const CelWrapper: React.FC<Props> = (props) => {
  return (
    <div className="w-full">
      <div
        className={`relative w-full border print:border-r-2 print:border-b-2 border-l-0 border-t-0 border-slate-200`}
        style={{
          paddingTop: "100%",
        }}
      >
        <div
          onClick={(e) => props.onClick()}
          className={`flex select-none sm:cursor-pointer outline-none transform text-center items-center justify-center absolute top-0 left-0 h-full w-full print:text-2xl text-3xl lg:text-3xl bg-white ${getCelClass(
            props
          )}`}
        >
          {props.number !== 0 && <div>{props.number}</div>}
          {!props.number && !props.readOnly && (
            <div className="grid w-full h-full grid-cols-3">
              {props.drafts?.map((value, index) => (
                <div
                  className={`text-2xs sm:text-xs transition duration-300 ease-in-out flex items-center justify-center select-none ${
                    value ? "text-slate-500" : "text-white"
                  } `}
                  key={index}
                >
                  {value || <>&nbsp;</>}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const CelContainer: React.FC<ContainerProps> = (props) => (
  <CelWrapper {...props} />
)

export const Cel = connector(CelContainer)
