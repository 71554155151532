import { connect, ConnectedProps } from "react-redux"
import { MatrixEntity } from "../../../entities/GameEntity"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (
  state: RootState,
  props: { matrix: MatrixEntity }
) => ({
  matrix: props.matrix,
  paused: state.play.paused,
})

const mapDispatch = (dispatch: any) => ({
  onUp: () => dispatch(actions.play.up()),
  onDown: () => dispatch(actions.play.down()),
  onLeft: () => dispatch(actions.play.left()),
  onRight: () => dispatch(actions.play.right()),
  onResume: () => dispatch(actions.play.resume()),
  onChange: (value: number) =>
    dispatch(actions.play.$changeCelOrDraft({ value })),
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<
  typeof connector
>
