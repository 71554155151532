import React from "react"
import { StatForShareableLink } from "../../../entities/StatEntity"

import SudokuAcademy from "../../../assets/badges/sudoku-academy.svg"
import { Badge } from "./Badge"
import { Transition } from "@headlessui/react"
import ArrowSmUpIcon from "@heroicons/react/20/solid/ArrowSmallUpIcon"
import { connector, ContainerProps } from "./containers/Level.containers"
import { FormattedMessage } from "../../FormattedMessage/FormattedMessage"
import { PointsStepEntity } from "../../../services/LevelAndPointsService"
import clsx from "clsx"

const Circle: React.FC<{ percentage: number }> = ({ percentage }) => {
  const stroke = 6
  const radius = 40
  const normalizedRadius = radius
  const circumference = normalizedRadius * 2 * Math.PI

  return (
    <div
      className="relative inline-block w-full overflow-hidden align-middle"
      style={{ paddingTop: "100%" }}
    >
      <svg
        version="1.1"
        viewBox={`0 0 ${radius * 2 + stroke} ${radius * 2 + stroke}`}
        className="absolute inset-0"
        preserveAspectRatio="xMinYMin meet"
      >
        <circle
          className="text-white opacity-50"
          strokeWidth={stroke}
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={radius + stroke / 2}
          cy={radius + stroke / 2}
        />
        <circle
          className="text-white duration-1000 ease-in-out delay-200 origin-center"
          strokeWidth={stroke}
          style={{ transform: "rotate(-90deg)" }}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - percentage * circumference}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={radius + stroke / 2}
          cy={radius + stroke / 2}
        />
      </svg>
    </div>
  )
}

export const Wrapper: React.FC<{
  stats: StatForShareableLink
  points: number
  steps: Array<PointsStepEntity>
  nextLevelPoints: number
  level: number
  levelUp: boolean
  totalPointsWon: number
  onNext: Function
}> = (props) => {
  return (
    <Badge>
      <img src={SudokuAcademy} className="h-3 mx-auto" />

      <div className="mt-8">
        <div className="relative flex items-center justify-center mx-auto w-36">
          <Circle percentage={props.points / props.nextLevelPoints} />
          <div className="absolute inset-0 font-display flex items-center justify-center">
            <div className="text-center">
              <p className="relative text-4xl font-medium text-white">
                {props.level}
                {props.levelUp && (
                  <ArrowSmUpIcon className="absolute top-0 -right-2 w-6 h-6 text-white animate-bounce" />
                )}
              </p>
              <p className="text-sm text-white opacity-50">
                {props.points}/{props.nextLevelPoints}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full mt-8">
          {props.steps
            .concat({ type: "total", value: props.totalPointsWon })
            .map(({ type, value }) => (
              <Transition
                appear={true}
                key={type}
                enter="transition-all duration-500 ease-in-out transform"
                enterFrom="opacity-0 -translate-x-3"
                enterTo="opacity-100 translate-x-0"
                className={clsx(
                  "flex items-center justify-between w-full",
                  type === "total" &&
                    "mt-2 border-t pt-2 border-dashed border-opacity-30 border-white"
                )}
              >
                <div
                  className={clsx(
                    "text-sm font-display font-bold text-white uppercase opacity-70"
                  )}
                >
                  {type === "base" && (
                    <FormattedMessage id="play.success.details.base" />
                  )}
                  {type === "no-error" && (
                    <FormattedMessage id="play.success.details.no-error" />
                  )}
                  {type === "daily-bonus" && (
                    <FormattedMessage id="play.success.details.daily-bonus" />
                  )}
                  {type === "no-hint" && (
                    <FormattedMessage id="play.success.details.no-hint" />
                  )}
                  {type === "launch-bonus" && (
                    <FormattedMessage id="play.success.details.launch-bonus" />
                  )}

                  {type === "hint-used" && (
                    <FormattedMessage id="play.success.details.hint-used" />
                  )}

                  {type === "three-errors" && (
                    <FormattedMessage id="play.success.details.three-errors" />
                  )}

                  {type === "ten-errors" && (
                    <FormattedMessage id="play.success.details.ten-errors" />
                  )}

                  {type === "total" && (
                    <FormattedMessage id="play.success.details.total" />
                  )}
                </div>
                <div className="font-medium text-right text-white">{value}</div>
              </Transition>
            ))}
        </div>
      </div>
    </Badge>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Level = connector(Container)
