import React from "react"
import { useIntl } from "react-intl"
import SudokuAcademy from "../../../assets/badges/sudoku-academy.svg"
import { StatForShareableLink } from "../../../entities/StatEntity"
import { secondsToHumanString } from "../../../utils/date"
import { Badge } from "./Badge"
import { Stars } from "./Stars"
import { Stats, Stat } from "./Stats"

export const Info: React.FC<{
  stats: StatForShareableLink
}> = (props) => {
  const intl = useIntl()

  return (
    <Badge>
      <img src={SudokuAcademy} className="h-3 mx-auto" />
      <Stars stats={props.stats} />
      <div className="w-1/4 h-0.5 mx-auto my-8 bg-white rounded opacity-70"></div>
      <Stats>
        <Stat
          label={intl.formatMessage({ id: "badge.level" })}
          value={intl.formatMessage({ id: `levels.${props.stats.level}` })}
        />
        <Stat
          label={intl.formatMessage({ id: "badge.duration" })}
          value={secondsToHumanString(props.stats.duration)}
        />
        <Stat
          label={intl.formatMessage({ id: "badge.errors" })}
          value={props.stats.errors}
        />
        <Stat
          label={intl.formatMessage({ id: "badge.clues" })}
          value={props.stats.clues}
        />
      </Stats>

      <div
        className="absolute top-0 bottom-0 left-0 right-0 z-10 w-full transform -translate-x-1/2 -translate-y-20 bg-white rounded-br-full opacity-10 rotate-12"
        style={{ height: "600px" }}
      ></div>
    </Badge>
  )
}
