import { RouteComponentProps } from "@reach/router"
import { connect, ConnectedProps } from "react-redux"
import { GameEntity } from "../../../entities/GameEntity"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props) => ({
  time: state.play.time,
  paused: state.play.paused,
})

const mapDispatch = (dispatch: any) => ({
  onTick: async () => {
    dispatch(actions.play.$tick())
  },
  onPause: async () => {
    dispatch(actions.play.pause())
  },
  onResume: async () => {
    dispatch(actions.play.resume())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
