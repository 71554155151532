import React from "react"
import { UserEntity } from "../../entities/UserEntity"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { LevelBar } from "../LevelBar/LevelBar"
import {
  connector,
  ContainerProps,
} from "./containers/AvatarAndStatsOnIndexPage.containers"

export const Wrapper: React.FC<{
  level: number
  points: number
  nextLevelPoints: number
  user: UserEntity | null
}> = (props) => {
  return (
    <div className="">
      <div className="flex justify-center">
        <div className="relative inline-block">
          <div className="absolute bottom-0 right-0 flex items-center justify-center w-7 h-7 mx-auto border-2 border-white text-white bg-blue-500 rounded-full sm:ml-0">
            <div className="text-center">
              <div className="text-sm font-medium font-display">
                {props.level}
              </div>
            </div>
          </div>

          <img
            src={`/avatars/${props.user?.avatar || "other/anonymous.jpg"}`}
            className="block w-20 h-20 mx-auto rounded-full sm:ml-0"
            alt={"avatar"}
          />
        </div>
      </div>

      <div className="relative w-full mt-4 text-center sm:pt-2 sm:mt-0">
        <div className="text-lg font-medium font-display text-slate-900  ">
          {props.user?.username || (
            <FormattedMessage id="index/avatar/not-authenticated/title" />
          )}
        </div>
        <div className="text-slate-500 text-sm">
          {!props.user?.email && (
            <FormattedMessage id="index/avatar/not-authenticated/description" />
          )}
        </div>

        <div className="mt-2">
          <LevelBar percentage={props.points / props.nextLevelPoints} />
        </div>

        <div className="flex font-display justify-center w-full text-sm font-medium text-slate-500   sm:justify-between">
          <div>{props.points}</div>
          <div className="px-1 sm:hidden">/</div>
          <div>{props.nextLevelPoints}</div>
        </div>
      </div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const AvatarAndStatsOnIndexPage = connector(Container)
